import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "@emotion/styled";
import { useForm } from "@mantine/form";
import {
  Autocomplete,
  Box,
  Button,
  Group,
  Input,
  Select,
  TextInput,
  Checkbox,
  LoadingOverlay,
  Table,
} from "@mantine/core";
import IconButton from "@mui/material/Button";
import "@fontsource/lato";

import MailIcon from "@mui/icons-material/MailOutlineOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ShowChartOutlinedIcon from "@mui/icons-material/ShowChartOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import validator from "validator";
import { tickerbellsColors } from "../themes/default";
import { pink } from "@mui/material/colors";

const AlertListPage = () => {
  const [alerts, setAlerts] = useState([
    {
      ticker: "AAC",
      phone_number: "",
      price: "1",
      alert_type: "lt",
    },
    {
      ticker: "AAC",
      phone_number: "",
      price: "1",
      alert_type: "lt",
    },
    {
      ticker: "AAC",
      phone_number: "",
      price: "1",
      alert_type: "lt",
    },
    {
      ticker: "AAC",
      phone_number: "",
      price: "1",
      alert_type: "lt",
    },
    {
      ticker: "AAC",
      phone_number: "",
      price: "1",
      alert_type: "lt",
    },
    {
      ticker: "AAC",
      phone_number: "",
      price: "1",
      alert_type: "lt",
    },
  ]);
  const [displayLoading, setDisplayLoading] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("tickerbells_jwt");
    console.log(axios.defaults.headers.common);
    if (token) {
      setDisplayLoading(true);
      axios
        .get("https://api.tickerbells.com/alerts/list", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((err) => {
          console.log("error", err);
          setDisplayLoading(false);
        })
        .then((resp) => {
          console.log(resp);
          setAlerts(resp.data.alerts);
          setDisplayLoading(false);
        });
    }
  }, []);
  return (
    <>
      <Layout>
        <AlertList
          alerts={alerts}
          setAlerts={setAlerts}
          displayLoading={displayLoading}
          setDisplayLoading={setDisplayLoading}
        ></AlertList>
      </Layout>
    </>
  );
};

const AlertList = ({
  alerts,
  setAlerts,
  displayLoading,
  setDisplayLoading,
}) => {
  const deleteAlert = (idx) => {
    setDisplayLoading(true);
    axios
      .delete(`https://api.tickerbells.com/alerts/delete?alertIdx=${idx}`)
      .catch((err) => {
        console.log("error", err);
        setDisplayLoading(false);
      })
      .then((resp) => {
        console.log(resp);
        if (resp.data.statusCode == 200) {
          console.log("success, current alerts:", alerts);
          window.location.reload(false);
        }
        setDisplayLoading(false);
      });
  };
  const rows = alerts.map((alert, idx) => (
    <tr key={`${alert.ticker}_${idx}`}>
      <td>{alert.ticker}</td>
      <td>{alert.alert_type}</td>
      <td>{alert.price}</td>
      <td>
        <IconButton aria-label="delete" size="small">
          <DeleteForeverIcon
            onClick={() => {
              console.log(idx);
              deleteAlert(idx);
            }}
            fontSize="small"
            sx={{ color: pink[500] }}
            key={`${alert}_${idx}`}
          />
        </IconButton>
      </td>
    </tr>
  ));

  return (
    <Table highlightOnHover={true} striped={true}>
      <thead>
        <tr>
          <th>Ticker</th>
          <th>Type</th>
          <th>Price</th>
          <th>
            <DeleteForeverIcon></DeleteForeverIcon>
          </th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
      <LoadingOverlay
        visible={displayLoading}
        transitionDuration={500}
      ></LoadingOverlay>
    </Table>
  );
};

const Layout = styled.div`
  // Required by overlay
  position: relative;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(18, 18, 18, 1) 35%,
    rgba(34, 23, 63, 1) 100%
  );
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  // Sidebar
  margin-left: 180px;

  table {
    rules: none;
    width: 100%;
  }
  thead {
    background: #3e4751;
    font-family: Lato;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    height: 96px;
    width: 1440px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12),
      0 1px 3px 0 rgba(0, 0, 0, 0.2);

    tr {
      height: 56px;
      width: 1208px;
      border: 1px solid ${tickerbellsColors.lightBlueGrayBorder};
      th {
        color: white;
      }
    }
    th {
      background: #3e4751;
      padding: 4px 8px;
      padding-left: 1.5rem;
      text-align: left;
      height: 16px;
      width: 128px;
      font-family: Lato;
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 16px;
      // Stickyness
      position: -webkit-sticky; /* Safari */
      position: sticky;
      top: 0;
      z-index: 2;
    }
  }
  tbody {
    overflow: visibile;
  }
  tbody tr {
    height: 48px;
    padding-top: 10px;
  }
  tbody:before {
    content: "@";
    display: block;
    line-height: 10px;
    text-indent: -99999px;
  }
  tbody tr:nth-of-type(odd) {
    background-color: #f5f5f5;
  }
  tbody tr:nth-of-type(even) {
    background-color: #ffffff;
  }
  td {
    height: 16px;
    // Number of headers / 100%
    color: ${tickerbellsColors.black};
    font-family: Lato;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
    border: 0;
    text-align: left;
    overflow: scroll;
    text-overflow: ellipsis;
  }

  button,
  button:link,
  button:visited,
  button:hover,
  button:active {
    border: none;
    cursor: pointer;
    background: none;
    height: 16px;
    color: ${tickerbellsColors.blue};
    font-family: Lato;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-decoration: none;
    text-align: left;
    min-width: 0;
  }
`;

export default AlertListPage;
