import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Link, useLocation } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import "@fontsource/lato";
import { Button } from "@mantine/core";
import axios from "axios";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    background-color: #f1f1f1;
  }
`;

export default function Navbar() {
  return (
    <>
      <GlobalStyle> </GlobalStyle>
      <NavBarHeader>
        <LogoWrapper></LogoWrapper>
        <LinkContainer>
          <MyLink url="/contact" text="Contact us"></MyLink>
        </LinkContainer>
        <LinkContainer>
          <MyLink url="/terms" text="Terms & Conditions"></MyLink>
        </LinkContainer>
        <LogoutButtonContainer>
          <LogoutButton></LogoutButton>
        </LogoutButtonContainer>
      </NavBarHeader>
    </>
  );
}

function MyLink({ url, text }) {
  const location = useLocation();
  return (
    <div>
      <Link to={url} style={{ textDecoration: "none" }}>
        <LinkText>{text}</LinkText>
        {location.pathname === url && <BottomBorder></BottomBorder>}
      </Link>
    </div>
  );
}

function LogoutButton() {
  const [displayLogout, setDisplayLogout] = useState(false);
  const onClick = () => {
    axios.defaults.headers.common["Authorization"] = "";
    localStorage.removeItem("tickerbells_jwt");
    window.location.reload(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("tickerbells_jwt");
    console.log(token);
    if (token) {
      setDisplayLogout(true);
    }
  }, [setDisplayLogout]);

  return (
    displayLogout && (
      <Button onClick={onClick} color="indigo">
        Logout
      </Button>
    )
  );
}

const NavBarHeader = styled.div`
  display: flex;
  height: 80px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(42, 30, 81, 1) 35%,
    rgba(83, 60, 159, 1) 100%
  );
  margin: 0;
  // for sidebar
  margin-left: 180px;
  body {
    margin: 0;
  }
`;

const LogoWrapper = styled.div`
  padding: 0px 35px 0px 35px;
  align-self: center;
  order: 0;
`;

const LogoutButtonContainer = styled.div`
  margin-left: auto;
  margin-right: 3rem;
  align-self: center;
`;

const LinkContainer = styled.div`
  margin-left: 30px;
`;

const LinkText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  width: 100px;
  align-self: flex-start;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  font-family: Lato;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
`;

const BottomBorder = styled.div`
  height: 5px;
  width: 100px;
  background: #35414d;
`;
