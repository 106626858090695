export const tickerbellsColors = {
  darkerBlue: "#17324B",
  darkBlue: "#004160",
  blue: "#0074A5",
  lightBlue: "#00B0EB",
  semiOpaqueLightBlue: "rgba(0, 176, 235, 0.7)",
  translucentLightBlue: "rgba(0, 176, 235, 0.1)",
  darkBlueGray: "#465C6D",
  blueGray: "#A0AEB8",
  lightBlueGray: "#DEEAF1",
  lightBlueGrayBorder: "#e3edf3",
  white: "#FFFFFF",
  black: "#00253A",
  lightGray: "#F5F5F5",
  teal: {
    flat: "#00BFA5",
  },
  deepPurple: "#9575CD",
  red: "#E01815",
  lightRed: "#EF5350",
  semiOpaqueRed: "rgba(239,83,80,0.7)",
  green: "#3C870D",
  semiOpaqueGreen: "rgba(100,221,23,0.5)",
};
