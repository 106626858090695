import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "@emotion/styled";
import { useForm } from "@mantine/form";
import {
  Autocomplete,
  Box,
  Button,
  Group,
  Input,
  Select,
  TextInput,
  Checkbox,
} from "@mantine/core";
import MailIcon from "@mui/icons-material/MailOutlineOutlined";
import ShowChartOutlinedIcon from "@mui/icons-material/ShowChartOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import validator from "validator";

const CreateAlertPage = () => {
  return (
    <>
      <Layout>
        <AlertsForm></AlertsForm>
      </Layout>
    </>
  );
};

const AlertsForm = () => {
  const form = useForm({
    initialValues: {
      email: "d@a.com",
      ticker: "AAC",
      price: "1",
      phone_number: "",
      alert_type: "lt",
    },

    validate: {
      email: (value) => (validator.isEmail(value) ? null : "Invalid email"),
      price: (value) =>
        validator.isCurrency(value) ? null : "Must be valid US currency",
      phone_number: (value) =>
        value === ""
          ? null
          : validator.isMobilePhone(value)
          ? null
          : "Must be a valid US phone number. Contact support if international",
    },
  });
  const onSubmit = (data) => {
    console.log("submitting data", data);
    axios
      .post("https://api.tickerbells.com/alerts/create", data)
      .catch((e) => {
        console.log("ERROR", e);
      })
      .then((resp) => {
        console.log("SUCCESS", resp);
      });
  };

  const [stocks, setStocks] = useState([{ value: "AAA", name: "A thing" }]);
  useEffect(() => {
    console.log("render");
    fetch(`./all_us_stocks.json`)
      .then((response) => response.json())
      .then((data) => {
        const newData = data.map((d) => {
          return { value: d.Symbol, name: d.Name };
        });
        setStocks(newData);
      });
  }, [setStocks]);

  return (
    <FormContainer>
      <AlertsFormLayout onSubmit={form.onSubmit(onSubmit)}>
        <AlertsFormHeader>Create Stock Alert</AlertsFormHeader>

        <Autocomplete
          required
          icon={<ShowChartOutlinedIcon></ShowChartOutlinedIcon>}
          data={stocks}
          size="xl"
          placeholder="Ticker"
          error={form.errors.ticker && "Ticker Not Found"}
          {...form.getInputProps("ticker")}
        />
        <TextInput
          required
          icon={<AttachMoneyOutlinedIcon></AttachMoneyOutlinedIcon>}
          size="xl"
          placeholder="Alert Price"
          error={form.errors.price && "Invalid price."}
          {...form.getInputProps("price")}
        />
        <Select
          required
          icon={<NotificationsActiveIcon></NotificationsActiveIcon>}
          placeholder="When to alert"
          size="xl"
          data={[
            {
              value: "lt",
              label: "Less Than Price",
              key: "1",
            },
            {
              value: "gt",
              label: "Greater Than Price",
              key: "2",
            },
          ]}
          {...form.getInputProps("alert_type")}
        />
        <TextInput
          required
          size="xl"
          placeholder="Email"
          icon={<MailIcon />}
          aria-invalid={form.errors}
          error={form.errors.email && "Invalid email."}
          {...form.getInputProps("email")}
        />
        <TextInput
          icon={<PhoneIphoneOutlinedIcon></PhoneIphoneOutlinedIcon>}
          size="xl"
          error={
            form.errors.phone_number &&
            "Invalid phone number. US numbers only accepted. If international, please contact supprt"
          }
          placeholder="___-___-____"
          {...form.getInputProps("phone_number")}
        />
        <Button variant="default" type="submit" color="dark">
          Create Alert
        </Button>
      </AlertsFormLayout>
    </FormContainer>
  );
};

const FormContainer = styled.div``;
const AlertsFormHeader = styled.div`
  font-weight: 700;
  font-size: 1.5rem;
`;

const AlertsFormLayout = styled.form`
  background-color: white;
  width: 280px;
  border-radius: 3px;
  padding: 4rem;
  margin: 2rem;
  display: flex;
  row-gap: 2rem;
  flex-direction: column;
  align-items: center;
  button,
  button:hover {
    background-color: #00cc0a;
  }
`;

const Layout = styled.div`
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(18, 18, 18, 1) 35%,
    rgba(34, 23, 63, 1) 100%
  );
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  // Sidebar
  margin-left: 180px;
`;

export default CreateAlertPage;
