import React, { useState } from "react";
import axios from "axios";
import styled from "@emotion/styled";
import { useForm } from "@mantine/form";
import { Button, TextInput, Textarea, Notification } from "@mantine/core";
import MailIcon from "@mui/icons-material/MailOutlineOutlined";
import DangerousIcon from "@mui/icons-material/Dangerous";
import validator from "validator";

const ContactUsPage = () => {
  return (
    <>
      <Layout>
        <ContactForm></ContactForm>
      </Layout>
    </>
  );
};

const ContactForm = () => {
  const form = useForm({
    initialValues: {
      email: "",
      comment: "",
    },

    validate: {
      email: (value) => (validator.isEmail(value) ? null : "Invalid email"),
    },
  });
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [displayLoading, setDisplayLoading] = useState(false);
  const [displayError, setDisplayError] = useState(false);

  const onSubmit = (data) => {
    console.log("submitting data", data);
    setDisplayLoading(true);
    axios
      .post("https://api.tickerbells.com/contact", data)
      .catch((e) => {
        console.log("ERROR", e);
        setDisplayError(true);
        setDisplayLoading(false);
      })
      .then((resp) => {
        console.log("SUCCESS", resp);
        setDisplaySuccess(true);
        setDisplayLoading(false);
      });
  };

  return (
    <>
      <FormContainer>
        <ContactFormLayout onSubmit={form.onSubmit(onSubmit)}>
          <ContactFormHeader>Send us an Email</ContactFormHeader>

          <TextInput
            required
            size="xl"
            placeholder="Email"
            icon={<MailIcon />}
            aria-invalid={form.errors}
            error={form.errors.email && "Invalid email."}
            {...form.getInputProps("email")}
          />
          <Textarea
            style={{ width: "100%" }}
            placeholder="Questions or comments"
            required
            size="xl"
            minRows={5}
            {...form.getInputProps("comment")}
          />
          <Button variant="default" type="submit" color="dark">
            Submit contact form
          </Button>
        </ContactFormLayout>
      </FormContainer>
      {displaySuccess && (
        <Notification
          color="teal"
          title="Contact form accepted!"
          onClose={() => {
            setDisplaySuccess(false);
          }}
        >
          We will respond and reach out to your email within 24 hours.
        </Notification>
      )}
      {displayLoading && (
        <Notification
          loading
          title="Uploading contact form to the server"
          disallowClose
        >
          Please wait until form is uploaded..
        </Notification>
      )}
      {displayError && (
        <Notification
          color="red"
          icon={<DangerousIcon />}
          title="Contact form failed to upload!"
          onClose={() => {
            setDisplayError(false);
          }}
        >
          Failed to upload form. Please correct any errors and try again.
        </Notification>
      )}
    </>
  );
};

const FormContainer = styled.div``;
const ContactFormHeader = styled.div`
  font-weight: 700;
  font-size: 1.5rem;
`;

const ContactFormLayout = styled.form`
  background-color: white;
  width: 280px;
  border-radius: 3px;
  padding: 4rem;
  margin: 2rem;
  display: flex;
  row-gap: 2rem;
  flex-direction: column;
  align-items: center;
  button,
  button:hover {
    background-color: #00cc0a;
  }
`;

const Layout = styled.div`
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(18, 18, 18, 1) 35%,
    rgba(34, 23, 63, 1) 100%
  );
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  // Sidebar
  margin-left: 180px;
`;

export default ContactUsPage;
