import { Routes, Route, Link } from "react-router-dom";
import "./App.css";
import AlertListPage from "./alert_list_page";
import CreateAlertPage from "./create_alert_page";
import ContactPage from "./contact_page";
import PaymentPage from "./payment_page";
import SuccessPage from "./payment_success_page";
import TermsPage from "./terms_conditions";
import Navbar from "./components/NavBar";
import Footer from "./components/Footer";
import SideBar from "./components/Sidebar";
import styled from "@emotion/styled";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");

function App() {
  const options = {
    // passing the client secret obtained from the server
    clientSecret: "sk_test_4LLafuJb3WUX6gDxjhDLmLOl",
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <Layout>
        <Navbar></Navbar>
        <SideBar> </SideBar>
        <Routes>
          <Route path="/subscribe/success" element={<SuccessPage />} />
          <Route path="/subscribe" element={<PaymentPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/terms" element={<TermsPage></TermsPage>} />
          <Route path="/payments" element={<PaymentPage />} />
          <Route path="/alerts" element={<AlertListPage />} />
          <Route path="/" element={<CreateAlertPage></CreateAlertPage>} />
        </Routes>
        <Footer></Footer>
      </Layout>
    </Elements>
  );
}
const Layout = styled.div`
  display: flex;
  flex-direction: column;
`;
export default App;
