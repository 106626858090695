import React from "react";
import axios from "axios";
import styled from "@emotion/styled";
import { useForm } from "@mantine/form";
import { Button, Card, Text, Group } from "@mantine/core";
import validator from "validator";

import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const PaymentPage = () => {
  return (
    <>
      <Layout>
        <PaymentForm></PaymentForm>
      </Layout>
    </>
  );
};

const PaymentForm = () => {
  const form = useForm({
    initialValues: {
      email: "",
      comment: "",
    },

    validate: {
      email: (value) => (validator.isEmail(value) ? null : "Invalid email"),
    },
  });

  const onSubmit = (subscrType) => {
    console.log("submitting data", subscrType);

    axios
      .post(
        `https://api.tickerbells.com/subscribe?subscrType=${subscrType}`,
        {}
      )
      .catch((e) => {
        console.log("ERROR", e);
      })
      .then((resp) => {
        console.log("SUCCESS", resp);
      });
  };

  return (
    <PaymentCards>
      <PaymentCard>
        <Card shadow="sm" p="lg">
          <Card.Section></Card.Section>

          <Group position="apart">
            <Text weight={500}>$10 /monthly</Text>
          </Group>

          <Text size="sm">Unlimited alerts</Text>
          <Text size="sm">Email notifications</Text>
          <Text size="sm">Text notifications</Text>
          <Text size="sm">Email support</Text>
          <Card.Section>
            <Button
              onClick={() => {
                onSubmit("monthly");
              }}
              variant="light"
            >
              Subscribe
            </Button>
          </Card.Section>
        </Card>
      </PaymentCard>
      <PaymentCard>
        <Card shadow="sm" p="lg">
          <Group position="apart">
            <Text weight={500}>$100 Forever Access</Text>
          </Group>
          <Text size="sm">Permanent paid membership</Text>
          <Text size="sm">Unlimited alerts</Text>
          <Text size="sm">Email notifications</Text>
          <Text size="sm">Text notifications</Text>
          <Text size="sm">Email support</Text>
          <Card.Section>
            <Button
              onClick={() => {
                onSubmit("forever");
              }}
              variant="light"
            >
              Buy Now
            </Button>
          </Card.Section>
        </Card>
      </PaymentCard>
    </PaymentCards>
  );
};

const PaymentCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding-top: 4rem;
  padding-left: 4rem;
`;
const PaymentCard = styled.div`
  width: 300px;
`;
const Layout = styled.div`
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(18, 18, 18, 1) 35%,
    rgba(34, 23, 63, 1) 100%
  );
  height: 100vh;
  display: flex;
  flex-direction: row;
  gap: 10rem;
  // Sidebar
  margin-left: 180px;
`;

export default PaymentPage;
