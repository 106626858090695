import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, Drawer, Loader } from "@mantine/core";
import "@fontsource/lato";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import TableRowsIcon from "@mui/icons-material/TableRows";

export default function SideBar() {
  const location = useLocation();
  console.log(location.pathname);
  return (
    <SidebarContainer>
      <Drawer
        styles={{
          drawer: { backgroundColor: "#000000", color: "white" },
        }}
        withCloseButton={false}
        opened={true}
        withOverlay={false}
        size="xs"
        trapFocus={false}
        closeOnEscape={false}
        closeOnClickOutside={false}
      >
        <LinksContainer>
          <LogoText>
            <Link to="/" style={{ textDecoration: "none" }}>
              Tickerbells
            </Link>
          </LogoText>
          <LinkWithIcon key="/">
            <AddAlertIcon></AddAlertIcon>
            <MyLink url="/" text="Create Alert"></MyLink>
          </LinkWithIcon>
          <LinkWithIcon key="alerts">
            <TableRowsIcon></TableRowsIcon>
            <MyLink url="/alerts" text="Alerts list"></MyLink>
          </LinkWithIcon>
        </LinksContainer>
      </Drawer>
    </SidebarContainer>
  );
}

function MyLink({ url, text }) {
  const location = useLocation();
  return (
    <div>
      <Link to={url} style={{ textDecoration: "none" }}>
        {text}
      </Link>
    </div>
  );
}

const LogoText = styled.div`
  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: white;
    font-weight: 900;
    text-decoration: none;
    font-size: 18px;
    border: none;
    cursor: pointer;
    background: none;
    text-decoration: none;
    text-align: left;
    min-width: 0;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  flex: 1;
  gap: 2rem;
  padding-left: 2rem;
`;

const SidebarContainer = styled.div``;
const CurrentLinkWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  color: #white;
  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: #white;
    border: none;
    cursor: pointer;
    background: none;
    height: 16px;
    font-family: Lato;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-decoration: none;
    text-align: left;
    min-width: 0;
  }
`;
const LinkWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  color: #b3b3b3;
  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: #b3b3b3;
    border: none;
    cursor: pointer;
    background: none;
    height: 16px;
    font-family: Lato;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-decoration: none;
    text-align: left;
    min-width: 0;
  }
`;
