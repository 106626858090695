import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, Loader } from "@mantine/core";
import "@fontsource/lato";
import { GoogleLogin } from "react-google-login";
import axios from "axios";
import { writeStorage } from "@rehooks/local-storage";

function login(googleAuthDict, setAuthToken, setDisplaySocial) {
  setDisplaySocial(null);
  axios
    .post("https://api.tickerbells.com/login", {
      token: googleAuthDict["tokenId"],
    })
    .then((resp) => {
      console.log(resp);
      if (resp.status == 200 || resp.status == 204) {
        writeStorage("tickerbells_jwt", resp.data.jwt);
        setAuthToken(resp.data.jwt);
        console.log("SUCCESSFUL LOGIN");
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${resp.data.jwt}`;
        setDisplaySocial(false);
        window.location.reload(false);
        return;
      }
      writeStorage("tickerbells_jwt", "");
      setAuthToken("");
      setDisplaySocial(true);
    });
}

export default function Footer() {
  // TODO: In Mantine docs Lookup: "Usage with react-router and other libraries"
  const [authToken, setAuthToken] = useState("");
  // true, false, Null (null is loading
  const [displaySocialLogin, setDisplaySocial] = useState(true);

  useEffect(() => {
    console.log("Rerender", authToken);
    const token = localStorage.getItem("tickerbells_jwt");
    if (token) {
      setAuthToken(token);
      setDisplaySocial(false);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
  }, [setAuthToken, setDisplaySocial]);

  const displayLoading = () => {
    if (displaySocialLogin === null) {
      return true;
    }
    return false;
  };
  return (
    displaySocialLogin && (
      <Layout>
        {displaySocialLogin ? (
          <ButtonContainer>
            <GoogleLogin
              clientId="393970666992-r5gl39md4rccjgo13cj4dat1f13pj38j.apps.googleusercontent.com"
              buttonText="Login With Google"
              onSuccess={(googleAuthDict) => {
                login(googleAuthDict, setAuthToken, setDisplaySocial);
              }}
              onFailure={(e) => {
                console.log("failure", e);
                setDisplaySocial(true);
              }}
              cookiePolicy={"single_host_origin"}
            />
          </ButtonContainer>
        ) : displaySocialLogin === null ? (
          <Loader color="white" size="lg"></Loader>
        ) : null}
      </Layout>
    )
  );
}

const ButtonContainer = styled.div`
  margin-right: 2rem;
`;

const Layout = styled.div`
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 80px;
  background: rgb(175, 41, 150);
  background: linear-gradient(
    90deg,
    rgba(175, 41, 150, 1) 0%,
    rgba(129, 107, 203, 1) 60%,
    rgba(81, 154, 244, 1) 100%
  );
  display: flex;
  justify-content: right;
  align-items: center;
  gap-right: 1rem;
`;
